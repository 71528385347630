import React, { useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { TimelineMax, gsap } from "gsap/dist/gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

import Landscape from "../images/landscape.inline.svg"
import { random } from "gsap/gsap-core"

if (typeof window !== "undefined") {
  gsap.registerPlugin(MotionPathPlugin)
}

const RefDiv = styled.div`
  position: fixed;
  z-index: -15;
  height: 300vh;
`
const LandscapeWrapper = styled(Landscape)`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  opacity: 0.4;

  @media (max-width: 768px) and (max-height: 500px) {
    height: auto;
  }

  @media screen and (orientation: landscape) and (min-width: 768px) {
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  @media screen and (orientation: landscape) and (min-width: 1200px) and (max-height: 500px) {
    top: -2rem;
  }

  @keyframes fade {
    to {
      opacity: 1;
    }
  }
  /* 
  #floatingFront,
  #floatingBack {
    visibility: hidden;
  } */

  #floatingFront {
    z-index: 10000;
  }

  #scrollPath {
    visibility: hidden;
  }
`

const Background = ({ opacity }) => {
  const svgWrapper = useRef(null)

  useEffect(() => {
    const [svgElements] = svgWrapper.current.children

    const floatingFront = svgElements.querySelector("#floatingFront")
    const floatingBack = svgElements.querySelector("#floatingBack")
    const bigFloat = svgElements.querySelector("#bigFloat")
    const smallFloat = svgElements.querySelector("#smallFloat")
    const floatBack1 = svgElements.querySelector("#floatBack1")
    const floatBack2 = svgElements.querySelector("#floatBack2")
    const floatBack3 = svgElements.querySelector("#floatBack3")

    const tpath1 = svgElements.querySelector("#p1")
    const tpath2 = svgElements.querySelector("#p2")
    const tpath3 = svgElements.querySelector("#p3")
    const triangles = svgElements.querySelectorAll("#triangles path")

    const spath = svgElements.querySelector("#scrollPath")
    const scroll = svgElements.querySelectorAll("#scroll path")

    /*     function mountainsF() {
      const tl = new TimelineMax({
        defaults: {
          duration: 3,
          ease: "linear",
          transformOrigin: "0.5 0.5",
          yoyo: true,
          repeat: -1,
        },
      })

      tl.set([floatingFront, floatingBack], {
        y: 0,
      })

      tl.addLabel("start")
        .to(bigFloat, { y: "1px" }, "start")
        .to(smallFloat, { y: "8px" }, "start")
        .to([floatBack1, floatBack2], { y: "-1px" }, "start")
        .to(floatBack3, { y: "1px" }, "start")

      return tl
    } */

    function birdsF() {
      const colors = ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"]

      const tl = new TimelineMax({
        repeat: -1,
        repeatDelay: random(20, 50),
      })

      tl.set(triangles, {
        fill: random(colors),
        scale: 0.8,
      })

      tl.fromTo(
        triangles,
        { fill: random(colors) },
        {
          motionPath: {
            path: tpath1,
            align: tpath1,
            alignOrigin: [0.5, 0.5],
            autoRotate: true,
            offsetY: random(-15, 15),
          },
          fill: random(colors),
          duration: 10,
          ease: "power1.inOut",
          stagger: 0.5,
        }
      )
        .fromTo(
          triangles,
          { fill: random(colors) },
          {
            motionPath: {
              path: tpath2,
              align: tpath2,
              alignOrigin: [0.5, 0.5],
              autoRotate: true,
              offsetY: random(0, 20),
            },
            fill: random(colors),
            duration: 7,
            ease: "linear",
            stagger: random(0.25, 0.8),
          }
        )
        .fromTo(
          triangles,
          { fill: random(colors) },
          {
            motionPath: {
              path: tpath3,
              align: tpath3,
              alignOrigin: [0.5, 0.5],
              autoRotate: true,
              offsetY: random(-10, 20),
            },
            fill: random(colors),
            duration: 7,
            ease: "linear",
            stagger: random(0.25, 0.8),
          }
        )
        .fromTo(
          triangles,
          { fill: random(colors) },
          {
            motionPath: {
              path: tpath2,
              align: tpath2,
              alignOrigin: [0.5, 0.5],
              autoRotate: true,
              offsetY: random(-5, 15),
            },
            fill: random(colors),
            duration: 5,
            ease: "linear",
            stagger: random(0.25, 0.8),
          }
        )

      return tl
    }

    function scrollF() {
      const tl = new TimelineMax({
        repeat: 2,
      })

      tl.set(scroll, { scale: 0.5 })

      tl.to(scroll, {
        motionPath: {
          path: spath,
          align: spath,
          alignOrigin: [1, 1],
          autoRotate: true,
        },
        duration: 30,
        ease: "bounce.out",
        stagger: 0.15,
      })

      return tl
    }

    const master = new TimelineMax()

    if (opacity === "full") {
      master.add(birdsF(), 3).add(scrollF(), 0)
    }
  }, [opacity])

  return (
    <>
      <RefDiv ref={svgWrapper}>
        <LandscapeWrapper
          style={
            opacity === "low"
              ? { opacity: 0.4 }
              : { animation: "fade 1s ease-in-out forwards" }
          }
          preserveAspectRatio="xMaxYMid slice"
        />
      </RefDiv>
    </>
  )
}

export default Background
