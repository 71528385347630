import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const HeaderWrapper = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  writing-mode: vertical-rl;
  text-orientation: upright;

  z-index: 20;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background-color: #ffffff91;

  h1 {
    text-align: center;
    writing-mode: horizontal-tb;
    text-align: right;
    font-family: "MuseoModerno", cursive;
    font-weight: 400;
    padding: 7px;

    .fe-dev {
      display: none;
    }
  }

  nav {
    justify-content: space-around;
    top: 0;
  }

  li {
    text-align: center;
    margin: 0;
    padding: 0.3rem 1rem;
  }

  li:first-of-type {
    text-align: right;
  }

  li:last-of-type {
    text-align: left;
  }

  @media screen and (orientation: landscape), (min-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    justify-content: space-between;
    writing-mode: unset;
    text-orientation: unset;
    background-color: #ffffff91;

    top: 0;
    right: 0;
    left: 0;
    max-height: 4.5rem;

    h1,
    nav {
      position: relative;
      width: 100%;
      text-align: left;
      top: 0;
      bottom: 0;

      .fe-dev {
        display: inline-block;
        font-size: 1rem;
        margin-left: 0.5rem;
      }
    }
    li {
      padding: 0.5rem 0;
    }
    h1 {
      right: 0;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5px 5%;
  }
`

const Navbar = styled.nav`
  background: transparent;
  font-size: 0.8rem;

  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    margin: 0;
    // background-color: #ffffff91;
  }

  li {
    margin: 1.2rem 0;
    letter-spacing: -1px;
  }

  a {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 15000;
  }

  a:hover {
  }

  @media screen and (min-width: 768px) {
    font-size: 1rem;

    ul {
      background-color: inherit;
    }

    li {
      margin: 0;
      letter-spacing: initial;
    }
  }
  @media screen and (max-width: 640px) {
    li {
      margin: 10px 0;
    }
  }
`

const Header = ({ siteTitle }) => {
  return (
    <HeaderWrapper>
      <h1>
        <Link to="/">
          iM<span className="fe-dev">web developer</span>
        </Link>
      </h1>
      <Navbar>
        <ul>
          <li>
            <Link to="/projects/" activeClassName="active">
              <span className="linkText">projects</span>
            </Link>
          </li>
          <li>
            <Link to="/toolbox/" activeClassName="active">
              <span className="linkText">toolbox</span>
            </Link>
          </li>
          <li>
            <Link to="/about/" activeClassName="active">
              <span className="linkText">about</span>
            </Link>
          </li>
          <li>
            <Link to="/contact/" activeClassName="active">
              <span className="linkText">contact</span>
            </Link>
          </li>
        </ul>
      </Navbar>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
