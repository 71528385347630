/* project's color palette */
export const color = {
  //primaryColor: "#730B1E", // dark red
  primaryColor: "#A00149",
  primaryHoverColor: "#1A7F7F", // greenish accent
  primaryActiveColor: "#1A7F7F", // greenish accent
  backgroundTransparencyBlack: "#000000d1", // about background
  backgroundTransparencyWhite: "#FFFFFFA8", // projects, toolbox, contact background
  textBackgroundBlack: "#FFF", // white
  textColorOnPrimary: "#FFF", // white
  //textColor: "#730B1E", // dark red - general text
  textColor: "#A00149",
  textColorInverted: "#F4B886",
  //textColorBright: "#B71533", // navbar text
  textColorBright: "#FF0A78",
}
