/* project's typography: fonts, typescale, etc */
export const primaryFont = "'Quicksand', sans-serif;"
export const secondaryFont = "'MuseoModerno', sans-serif;"

export const typeScale = {
  header1: "1.8rem",
  header2: "1.6rem",
  header3: "1.4rem",
  header4: "1.2rem",
  header5: "1.1rem",
  paragraph: "1rem",
  helperText: "0.8rem",
  copyrightText: "0.7rem",
}
