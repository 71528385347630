/* project's global styles */
import styled from "@emotion/styled"
import { typeScale, primaryFont, color } from "."

export const Global = styled.div`
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background: ${color.background};
  font-family: ${primaryFont};
  font-size: ${typeScale.paragraph};
  color: ${color.primaryColor};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    font-size: ${typeScale.header1};
  }
  h2 {
    font-size: ${typeScale.header2};
  }
  h3 {
    font-size: ${typeScale.header3};
  }
  h4 {
    font-size: ${typeScale.header4};
  }
  h5 {
    font-size: ${typeScale.header5};
  }
  h6 {
    font-size: ${typeScale.header6};
  }

  a,
  a:visited {
    text-decoration: none;
    color: ${color.textColorBright};
  }

  a.active {
    color: ${color.primaryActiveColor};
    font-weight: bold;
  }
`
